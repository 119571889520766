import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { FormProvider } from "./context/FormContext";
import routes from "./routes";

function App() {
  return (
    <FormProvider>
      <Router>
        <Routes>
          {routes.map((route, i) => (
            <Route key={i} path={route.path} element={<route.element />} />
          ))}
        </Routes>
      </Router>
    </FormProvider>
  );
}

export default App;

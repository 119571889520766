import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./confirm.css";
import "./popup.css";
import FormLogo from "../../components/FormLogo";
import { useForm } from "../../context/FormContext";

interface ISubmitButton {
  buttonValue: string;
  buttonColor: string;
}

function Confirm() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [checked, setChecked] = useState<boolean>(false);
  const [requiredState, setRequiredState] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean | undefined>(
    undefined
  );

  const [submitButton, setSubmitButton] = useState<ISubmitButton>({
    buttonValue: "Submit",
    buttonColor: "#007bff",
  });

  const navigate = useNavigate();
  const {
    formData: {
      firstName,
      lastName,
      otherNames,
      email,
      youtubeVideoUrl,
      dateOfBirth,
      interests,
      languagesSpoken,
      languagesToTeach,
      workExperiences,
      educationalQualifications,
      aboutYou,
      aboutYouAsTeacher,
      certifications,
      selectedInterest,
      newLanguageSpoken,
      selectedLanguagesSpokenProficiency,
      selectedLanguageTaught,
      selectedLanguageTaughtProficiency,
      company,
      title,
      description,
      startDate,
      endDate,
      institution,
      newFieldOfStudy,
      selectedEduLevel,
      dateStartedEdu,
      dateEndedEdu,
      newCertificate,
      issuingOrganization,
      issueDate,
    },
  } = useForm();

  const handleBack = () => {
    navigate("/register");
  };

  const handleChecked = () => {
    setChecked(!checked);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!checked) {
      setRequiredState("You are required to confirm by ticking the box above");
      return;
    }

    setRequiredState("");

    // setIsSubmitting(true);
    console.log("before submiting........");
    // const url = "https://pluri-api-service.onrender.com/teachers";

    const moreLanguagesSpoken = {
      language: newLanguageSpoken,
      proficiency: selectedLanguagesSpokenProficiency,
    };

    const moreLanguagesTaught = {
      language: selectedLanguageTaught,
      proficiency: selectedLanguageTaughtProficiency,
    };

    const moreWorkExperiences = {
      company: company,
      title: title,
      description: description,
      startDate: startDate,
      endDate: endDate,
    };

    const moreEducation = {
      institution: institution,
      fieldOfStudy: newFieldOfStudy,
      level: selectedEduLevel,
      startDate: dateStartedEdu,
      endDate: dateEndedEdu,
    };

    const moreCertifications = {
      name: newCertificate,
      issuingOrganization: issuingOrganization,
      issueDate: issueDate,
    };

    const formData = {
      firstName,
      lastName,
      otherNames,
      email,
      youtubeVideoUrl,
      dateOfBirth,
      interests: [...interests, selectedInterest],
      languagesSpoken: [...languagesSpoken, moreLanguagesSpoken],
      languagesTaught: [...languagesToTeach, moreLanguagesTaught],
      workExperiences: [...workExperiences, moreWorkExperiences],
      educationalQualifications: [...educationalQualifications, moreEducation],
      aboutYou,
      aboutYouAsTeacher,
      otherCertifications: [...certifications, moreCertifications],
    };

    if (formData) createTeacher(formData);
  };

  const createTeacher = (payload: any) => {
    // TODO: the uri should be abstracted as a constant or env variable
    const uri = "https://pluri-api-service.onrender.com/teachers";

    setSubmitButton({
      buttonValue: "Submitting...",
      buttonColor: "#808080",
    });

    fetch(uri, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        return response.text();
      })
      .then((response) => JSON.parse(response))
      .then((response) => {
        if (response.statusCode !== 200) {
          setError(response.message);

          setSubmitButton({
            buttonValue: "Error Processing",
            buttonColor: "#007bff",
          });
          return;
        }
        // setIsSubmitting(false);
        setSubmitButton({
          buttonValue: "Submitted",
          buttonColor: "green",
        });
        console.log("form submitted.......");
        setError("");
        setIsSubmitting(false);
      })
      .catch((error) => {
        console.log(error);
        setError(
          error +
            " " +
            "Something unexpected happened our engineers are working to fix that try again after a while"
        );
        setSubmitButton({
          buttonValue: "Submit",
          buttonColor: "#007bff",
        });
      });
  };

  return (
    <>
      <div className="confirm-container">
        <FormLogo />
        <h1>Confirm your credentials</h1>
        <h3>Name: {firstName}</h3>
        <h3>Last Name: {lastName}</h3>
        <h3>Other Names: {otherNames}</h3>
        <h3>Email Address: {email}</h3>
        <h3>YouTube Url: {youtubeVideoUrl}</h3>
        <h3>Date of Birth: {dateOfBirth}</h3>

        <h3>Interests:</h3>
        <ul>
          {interests.map((each: any, key) => (
            <li key={key}>{each}</li>
          ))}
          {interests.includes(selectedInterest) || selectedInterest === "" ? (
            ""
          ) : (
            <li>{selectedInterest}</li>
          )}
        </ul>

        <h3>Spoken Languages:</h3>
        <ul>
          {languagesSpoken.map((each: any, key) => (
            <li key={key}>
              {each.language} - {each.proficiency}{" "}
            </li>
          ))}
          {languagesSpoken.find(
            (a) => a.language.toLowerCase() === newLanguageSpoken.toLowerCase()
          ) ||
          newLanguageSpoken === "" ||
          selectedLanguagesSpokenProficiency === "" ? (
            ""
          ) : (
            <li>
              {" "}
              {newLanguageSpoken} {" -"} {selectedLanguagesSpokenProficiency}{" "}
            </li>
          )}
        </ul>

        <h3>Language(s) to Teach:</h3>
        <ul>
          {languagesToTeach.map((each: any) => (
            <li>
              {each.language} - {each.proficiency}{" "}
            </li>
          ))}
          {languagesToTeach.find(
            (a) => a.language === selectedLanguageTaught
          ) || selectedLanguageTaught === "" ? (
            ""
          ) : (
            <li>
              {selectedLanguageTaught} - {selectedLanguageTaughtProficiency}{" "}
            </li>
          )}
        </ul>

        <h3>Work Experience:</h3>
        <ul>
          {workExperiences.map((each: any) => (
            <li>
              <div className="role">{each.title}</div>
              <div className="organization">{each.company} </div>
              <div className="dates">
                {each.startDate} - {each.endDate}{" "}
              </div>
              <div className="role-description">{each.description} </div>
            </li>
          ))}

          {title === "" ||
          company === "" ||
          description === "" ||
          startDate === "" ||
          endDate === "" ? (
            ""
          ) : (
            <li>
              <div className="role">{title}</div>
              <div className="organization">{company} </div>
              <div className="dates">
                {startDate} - {endDate}{" "}
              </div>
              <div className="role-description">{description} </div>
            </li>
          )}
        </ul>
        <h3>Education:</h3>
        <ul>
          {educationalQualifications.map((each) => (
            <li>
              <div className="edu">{each.level}</div>
              <div className="edu">{each.fieldOfStudy}</div>
              <div className="edu-insti">{each.institution}</div>
              <div className="edu-dates">
                {each.startDate} - {each.endDate}
              </div>
            </li>
          ))}
          {institution === "" ||
          newFieldOfStudy === "" ||
          selectedEduLevel === "" ||
          dateStartedEdu === "" ||
          dateEndedEdu === "" ? (
            ""
          ) : (
            <li>
              <h4 className="edu">{selectedEduLevel}</h4>
              <div className="edu">{newFieldOfStudy}</div>
              <div className="edu-size">{institution}</div>
              <div className="dates">
                {dateStartedEdu} - {dateEndedEdu}
              </div>
            </li>
          )}
        </ul>

        <h3 className="about-label">About You:</h3>
        <div className="about">{aboutYou} </div>

        <h3 className="about-label">About You As A Teacher:</h3>
        <div className="about">{aboutYouAsTeacher} </div>

        <h3>Certifications:</h3>
        <ul>
          {certifications.map((each: any) => (
            <li>
              <div className="role">{each.name}</div>
              <div className="organization">{each.issuingOrganization} </div>
              <div className="dates">{each.issueDate}</div>
            </li>
          ))}

          {newCertificate === "" ||
          issuingOrganization === "" ||
          issueDate === "" ? (
            ""
          ) : (
            <li>
              <div className="role">{newCertificate}</div>
              <div className="organization">{issuingOrganization} </div>
              <div className="dates">{issueDate}</div>
            </li>
          )}
        </ul>

        <div className="termsAndConditions">
          <input checked={checked} onChange={handleChecked} type="checkbox" />
          <label>Yes, I agree to the terms & conditions.</label>
        </div>

        {requiredState && <div style={{ color: "red" }}>{requiredState}</div>}

        <button className="edit-button" onClick={handleBack}>
          Edit
        </button>

        <button
          onClick={handleSubmit}
          className="submit-button"
          // style={{ backgroundColor: isSubmitting ? "#808080" : "" }}
          style={{ backgroundColor: submitButton.buttonColor }}
          type="submit"
        >
          {/* {isSubmitting ? "Submitting..." : "Submit"} */}
          {submitButton.buttonValue}
        </button>

        {error && <div className="submit-error"> {error} </div>}
      </div>

      {isSubmitting === false && <FormSubmitted />}
    </>
  );
}

function FormSubmitted() {
  return (
    <div id="popup" className="popup">
      <div className="popup-content">
        <h2 className="thank-you">Thank you for successfully submitting!</h2>
        <p>
          {" "}
          We will contact you shortly. Kindly check your mail for more
          information.
        </p>
        <br />
        <a href="https://www.pluritongues.com">
          <button>Go to site</button>
        </a>
      </div>
    </div>
  );
}
export default Confirm;

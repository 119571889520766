import Logo from "../img/LogoSvg.png";

function FormLogo(){
    return (
        <div>
            <img 
            src={Logo} 
            alt="logo" 
            style={{
                width: '200px',
                height: 'auto',
                display: 'block',
                margin: 'auto'
            }}
            />
        </div>
    )
}

export default FormLogo
import React, { createContext, ReactNode, useContext, useState } from "react";

interface IRegPayLoad {
    firstName: string;
    lastName: string;
    otherNames: string;
    email: string;
    youtubeVideoUrl: string;
    dateOfBirth: string;
    interests: string[];
    languagesSpoken: ILanguageSpoken[];
    languagesToTeach: ILanguageTaught[];
    workExperiences: IWorkExperience[];
    educationalQualifications: IEducation[];
    aboutYou: string;
    aboutYouAsTeacher: string;
    certifications: ICertification[]
    selectedInterest: string;
    newLanguageSpoken: string;
    selectedLanguagesSpokenProficiency: string;
    selectedLanguageTaught: string;
    selectedLanguageTaughtProficiency: string;
    company: string;
    title: string;
    description: string;
    startDate: string;
    endDate: string;
    institution: string;
    newFieldOfStudy: string;
    selectedEduLevel: string;
    dateStartedEdu: string;
    dateEndedEdu: string;
    newCertificate: string;
    issuingOrganization: string;
    issueDate: string;
}

interface ILanguageSpoken {
    language: string;
    proficiency: string;
}

interface ILanguageTaught {
    language: string;
    proficiency: string;
}

interface IWorkExperience {
    startDate: string;
    endDate: string;
    company: string;
    title: string;
    description: string;
}
 
interface IEducation {
    institution: string;
    fieldOfStudy: string;
    level: string;
    startDate: string;
    endDate: string;
}

interface ICertification {
    name: string;
    issuingOrganization: string;
    issueDate: string;
}

interface FormContextValue {
    formData: IRegPayLoad;
    setFormData: React.Dispatch<React.SetStateAction<IRegPayLoad>>
}  
 
const initialFormData: IRegPayLoad = {
    firstName: "",
    lastName: "",
    otherNames: "",
    email: "",
    youtubeVideoUrl: "",
    dateOfBirth: "",
    interests: [],
    languagesSpoken: [],
    languagesToTeach: [],
    workExperiences: [],
    educationalQualifications: [], 
    aboutYou: "",
    aboutYouAsTeacher: "",
    certifications: [],
    selectedInterest: "",
    newLanguageSpoken: "",
    selectedLanguagesSpokenProficiency: "",
    selectedLanguageTaught: "",
    selectedLanguageTaughtProficiency: "",
    company: "",
    title: "",
    description: "",
    startDate: "",
    endDate: "",
    institution: "",
    newFieldOfStudy: "",
    selectedEduLevel: "",
    dateStartedEdu: "",
    dateEndedEdu: "",
    newCertificate: "",
    issuingOrganization: "",
    issueDate: ""
}

const FormContext = createContext<FormContextValue | undefined>(undefined)

export const FormProvider:React.FC<{children:ReactNode}> = ({children}) => {

    const [formData, setFormData] = useState(initialFormData)

    return (
        <FormContext.Provider value={{formData, setFormData }}>
            {children}
        </FormContext.Provider>
    )
}

export const useForm = () => {
    const context = useContext(FormContext);
    if (!context) {
        throw new Error('useForm must be used within a FormProvider')
    }
    return context
}